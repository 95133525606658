/* website: 3793-saintjoghntoyota1
 * created at 2022-08-30 4:00 by Rymma
 */

// Import all makes and organization styles files
@import "../templates/makes/toyota.scss";
@import "../utils/icons.scss";

@include breakpoint(mobile){
  .widget-sr {
    &.dealer__saint-john-toyota {
      .sr-modal__wrapper{
        margin-top: 50px;
      }
      .sr-modal--white-close{
        margin-top: 100px;
      }
    }
  }
}